<template>
  <div class="menus-page">
    <h1 class="display-1">Menus</h1>
    <p>Découvrez une cuisine singulière et soignée dans une ambiance florale et festive. Notre menu propose une variété de plats exquis, préparés avec des ingrédients frais et de qualité, pour vous offrir une expérience culinaire inoubliable.</p>
    <div class="menu-buttons">
      <div class="menu-buttons-row">
        <a href="./menus/midi.pdf" target="_blank" class="menu-button">Midi</a>
        <a href="./menus/soir.pdf" target="_blank" class="menu-button">Soir</a>
        <a href="./menus/boissons.pdf" target="_blank" class="menu-button">Boissons</a>
        <a href="./menus/cocktails.pdf" target="_blank" class="menu-button">Cocktails</a>
        <a href="./menus/vins.pdf" target="_blank" class="menu-button">Vins</a>
      </div>
      <div class="menu-buttons-row">
        <a href="./menus/midiEN.pdf" target="_blank" class="menu-button en-button">Lunch</a>
        <a href="./menus/soirEN.pdf" target="_blank" class="menu-button en-button">Dinner</a>
        <a href="./menus/boissonsEN.pdf" target="_blank" class="menu-button en-button">Drinks</a>
        <a href="./menus/cocktailsEN.pdf" target="_blank" class="menu-button en-button">Cocktails</a>
        <a href="./menus/vinsEN.pdf" target="_blank" class="menu-button en-button">Wines</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenusPage',
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0..900;1..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gotham:wght@100..900&display=swap');

.menus-page {
  padding: 2rem;
  font-family: 'Gotham', sans-serif;
}

.display-1 {
  font-family: 'Fjalla One', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.menu-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.menu-buttons-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.menu-button {
  padding: 0.5rem 1rem;
  border: 1px solid #1a1a1a;
  background-color: #fbecd2;
  color: #1a1a1a;
  text-transform: uppercase;
  font-family: 'Fjalla One', sans-serif;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.menu-button:hover {
  background-color: #1a1a1a;
  color: #fbecd2;
}

.en-button {
  background-color: #e0e0e0;
}

.en-button:hover {
  background-color: #1a1a1a;
  color: #e0e0e0;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.subtitle {
  font-family: 'Merriweather Sans', sans-serif;
  font-weight: 300;
  font-style: italic;
}

/* Responsive Styles */
@media (max-width: 1210px) {

  .vertical-text {
    font-size: 2.5em;
  }

  .bigneon{
    font-size: 5em;
  }

  .nav-top {
    height: 3rem;
  }

  h1{
    font-size: 3em;
  }

  h2{
    font-size: 1.6em;
  }

  .nav-link {
    font-size: 0.9rem;
  }

}

@media (max-width: 610px) {
  .nav-side {
    width:3em;
  }

  .nav-top {
    flex-direction: column;
    height: auto;
  }

  .nav-link {
    padding: 0.5rem 0;
  }

  .separator {
    display: none;
  }

  .main-content {
    flex-direction: column;
  }

  .content-text {
    padding: rem;
  }

  .content{
    margin-left: 3em;
  }

  .bigneon {
    font-size: 5rem;
  }

  .lineleft{
    border: none;
  }
  .lineright{
    border: none;
  }
  .linetop{
    border: none;
  }

  .footer {
    flex-direction: column;
    text-align: center;
  }
  .motif{
    display:none;
  }
  .notebas {
    margin-bottom: 5rem;
  }
}
</style>
